.filter-menu section {
  margin-top: 1em;
  padding: 1em 2em;
  background: #f9f9f9;
  border-top: 1px solid var(--primary-border-color);
  border-bottom: 1px solid var(--primary-border-color);
}
.filter-menu section.filter-tag-container {
  position: relative;
  border: none;
  border-top: 1px solid var(--primary-border-color);
  background: white;
  padding: 1em 1.5em;
}
.filter-menu h3 {
  font-size: 1em;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  padding-left: 2em;
  text-transform: uppercase;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  padding-right: 2em;
}

.filter-menu .button-view {
  position: relative;
  padding-left: 2.5em;
  height: 2em;
  line-height: 2em;
  margin-left: 1em;
  color : #333;
}
.filter-menu .button-view.map
{
  margin-left: 0em;
}
.filter-menu .button-view:nth-child(2) {
  margin-left: 0;
}

.filter-menu .button-view.grid {
  margin-top: 1em;
}
.filter-menu .button-view {

}
.filter-menu .button-view span {

  position: absolute;
  left: 0;
  top: 0;
  width: 2em;
  height: 2em;
  border: 1px solid var(--primary-color);
  border-radius: 2em;

}
.filter-menu .button-view:hover span, .filter-menu .button-view.active span
{
  background: var(--primary-color);
}
.filter-menu .button-view span:before {
  content : "";
  position: absolute;
  left: -1px;
  top: -1px;
  width: 2em;
  height: 2em;
  background: var(--primary-color);
  border-radius: 2em;
  mask-repeat: no-repeat;
  mask-position: center;
}
.filter-menu .button-view:hover span:before,.filter-menu .button-view.active span:before {
  background: white;
}
.filter-menu .button-view.map span:before {
  mask-image: url('../img/icon-map-dark.svg');
}

.filter-menu .button-view.grid span:before {
  mask-image: url('../img/icon-grid-dark.svg');
}

.filter-menu .button-view.list span:before {
  mask-image: url('../img/icon-list-dark.svg');
}

.filter-menu .button-view.map:hover span:before, .filter-menu .button-view.map.active span:before {
  mask-image: url('../img/icon-map-light.svg');
}

.filter-menu .button-view.grid:hover span:before, .button-view.grid.active span:before {
  mask-image: url('../img/icon-grid-light.svg');
}

.filter-menu .button-view.list:hover span:before, .button-view.list.active span:before {
  mask-image: url('../img/icon-list-light.svg');
}

.filter-menu  .form-group {
  padding-left: 1em;
}

.filter-menu .form-group:first-child {
  padding-left: 0;
}

section .price-range {
  position: relative;
}

.filter-menu input[type=range] {
  width: 100%;
  position: absolute;
  left: 0;
  top: 1.1em;
  pointer-events: none;
  -webkit-appearance: none;
  outline: none;
  background-color: transparent;
  border:1px solid var(--primary-border-color);
  border-radius: 10px;
  height: 7px;
}

.filter-menu input[type=range]::-webkit-slider-thumb {
  pointer-events:auto;
  -webkit-appearance: none;
  background-color: var(--primary-color);
  border: 2px solid white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: 1.1rem;
  height: 1.1rem;
  position: relative;
  z-index: 1;
}

#filter-price-range-max {
  background-color: transparent;
}

.price-display {
  margin-top: 1em;
}
.price-slider-container {
  position: relative;
  height: 30px;
}
form.floor-type .form-group {
  flex-basis: 100%;
}

.button-clear {
  position: absolute;
  right: 2em;
  top: -3em;
  padding:0.5em 1em;
  padding-left: 2.6em;
  font-weight: normal;
  text-transform: uppercase;

}
.button-clear:before {
  content: "";
  background: white;
  position: absolute;
  left: 10px;
  top: 4px;
  width: 1.7em;
  height: 1.7em;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url('../img/icon-trash.svg');
}

.form-group
{
  margin-bottom:0.3rem;
  padding: 0.1rem 0 0 0;
}

@media (max-width: 1440px) {
  .filter-menu h3 {
    margin-top: 1.2rem;
  }
  .filter-menu section {
    padding: 1em 2em;
  }

}
.filter-building-type-toggle-btn .icon{
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  margin-right: 0.4em;
  background: #333;

}
.filter-building-type-toggle-btn .icon.icon-up{
  mask-image: url('../img/icon-arrow-up.svg');
  mask-repeat: no-repeat;
  mask-size: 95%;
  mask-position: center;
}
.filter-building-type-toggle-btn .icon.icon-down{
  mask-image: url('../img/icon-arrow-down.svg');
  mask-repeat: no-repeat;
  mask-size: 95%;
  mask-position: center;
}


.search-list-container {
  position: absolute;
  left: -70px;
  background: white;
  height: calc(100vh);
  width: 70px;
}

.search-total {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  font-weight: bold;
  border-right: 1px solid var(--primary-border-color);
}

.search-list {

  overflow-y: scroll;
  height: calc(100% - 68px);
}
/* width */
.search-list::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.search-list::-webkit-scrollbar-track {
  background: #f7f7f7;
  margin-right: 10px;

}

/* Handle */
.search-list::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

