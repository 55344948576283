.map-marker
{
    width: 6rem;
    position: absolute;
    transform:  translate(-50%, calc(-50% - 2rem));

}

.map-marker-point span {
    position: absolute;
    background: var(--map-marker-color);
    border-radius: 1rem;
    padding: 0.3rem 0.6rem;
    color:white;
    font-size:0.8rem;
    display:block;
    width: 12rem;
    text-align: center;
    top:50%;
    left:50%;
    transform: translate(-50%,-140%);
}
.map-marker-point {
    background : var(--map-marker-color);
    width: 19px;
    height: 19px;
    border-radius: 50%;
    border:2px solid white;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    transition: all ease .5s;
    transform: translateY(-50px);
    opacity: 0;
}
.map-marker-point.loaded {
    transform: translateY(0);
    opacity: 1;
}
.map-marker-point:hover,.map-marker-point.blue {
    background: rgb(37, 53, 107);
}
.map-marker-point.down span {
    transform: translate(-50%, 40%);
}

.distance-label {
    position: fixed;
    background: var(--map-text-color);
    left: 0;
    top: 4.7rem;
    padding: 22px 15px;
    color: white;
    font-size: 2rem;
    line-height: 0px;

}
.distance-label span {
    font-size:1rem;
}
.duration-label {
    position: fixed;
    background: var(--map-text-color);
    left: 0;
    top: 2rem;
    padding: 14px 15px;
    color: white;
    font-size: 1.3rem;
    line-height: 9px;

}
.map-container {
    width:100%;
    height: 100%;
}

.location-list {

}