.fav-button
{
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.7em 2.3em;
    font-weight: normal;
    border: 1px solid var(--primary-border-color);
    color: #333;
}

.icon-like
{
    position: relative;
    width:1.5rem;
    height: 1.5rem;

}

.icon-like:before
{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    width: 1.5em;
    height: 1.5em;
    background-color: var(--primary-color);;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url('../img/icon-like.svg');
}
.fav-button:hover, .fav-button.is-fav
{
    color: white;
    background: var(--primary-color);
}
.fav-button:hover .icon-like:before, .fav-button.is-fav .icon-like:before
{
    background: url('../img/icon-like-light-fill.svg') no-repeat center;
    mask-image: url('../img/icon-like-fill.svg');
}


@media (max-width: 1440px) {
    .fav-button
    {
        padding: 0.7em 1.6em;
    }
    .fav-share-container {
        border-bottom: 0;
    }
}