.view-container {
  display: flex;
  border-bottom: 1px solid var(--primary-border-color);
}
.view-container button {
  color : #333;
  font-size:11px ;
  padding: 2em 1em;
  flex-grow: 1;
  flex-basis: 50%;
}
.view-container button:first-child {
  border-right : 1px solid var(--primary-border-color);
}

.btn-mapview {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  padding: 1em;
}
.btn-mapview .icon{
  display: inline-block;
  width: 2em;
  height: 2em;
  margin-right: 0.7em;
  background: var(--primary-color);

}
.btn-mapview .icon{
  mask-image: url('../img/icon-mappath.svg');
  mask-repeat: no-repeat;
  mask-size: 95%;
  mask-position: center;
}

.btn-mapview:hover,
.btn-mapview.active{
  background: var(--primary-color);
  color: white;
}
.btn-mapview:hover .icon,
.btn-mapview.active .icon
{
  background: white
}
.btn-real-shooting {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  padding: 1em;
}
.btn-real-shooting .icon{
  display: inline-block;
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
  background: var(--primary-color);

}
.btn-real-shooting .icon{
  mask-image: url('../img/icon-realshot.svg');
  mask-repeat: no-repeat;
  mask-size: 95%;
  mask-position: center;
}

.btn-real-shooting:hover,
.btn-real-shooting.active{
  background: var(--primary-color);
  color: white;
}
.btn-real-shooting:hover .icon,
.btn-real-shooting.active .icon
{
  background: white
}

.location-cat-container {
  z-index: 1;
  display: flex;
  background: white;
  padding: 2em;


}

.location-cat-item {

  margin-right: 0.5rem;
}

.location-cat-item .form-group {
  margin-bottom: 0;
}

.location-list {
  overflow-y: scroll;
  height: calc(100vh - 278px);
  margin-right: 5px;
}

/* width */
.location-list::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.location-list::-webkit-scrollbar-track {
  background: #f7f7f7;
  margin-right: 10px;

}

/* Handle */
.location-list::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}